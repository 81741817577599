import React, { useState, useEffect, useMemo, memo, useRef, useCallback } from "react";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import Link from "next/link";
import ModalTypes from "common/constants/modalTypes";
import { useModalContext } from "contexts/Modal";
import { getSearchQueryString, generateQueryIsbnUrl, parseMultipleGridIsbns } from "common/utils";
import { ACCENT } from "common/constants/buttonTypes";
import { SEARCH, BULK_COMPARISON, ADVANCED_SEARCH_PARAMS } from "common/constants/pathRoutes";
import { BUY_STATE, SELL_STATE } from "common/constants/searchTypes";
import { useResourceType } from "common/hooks";
import { searchTabsConfig, getInitialActiveTab } from "./utils";
import * as S from "./Search.styled";
import { ReactComponent as zoomIcon } from "public/icons/zoom.svg";
import { useAppContext } from "contexts";
import { setIsScanning } from "contexts/App/actions";
import { addErrorNotification } from "common/notifications";

/**
 * Main Search component
 */

const Search = () => {
  const searchRef = useRef(null);
  const [{
    isScanning
  }, dispatch] = useAppContext();
  const [isMobileFlag, setIsMobileFlag] = useState(false);
  const [isTabletFlag, setIsTabletFlag] = useState(false);
  const [isMultiLookupHintVisible, setIsMultiLookupHintVisible] = useState(false);
  const router = useRouter();
  const {
    modalAction
  } = useModalContext();
  const searchQueryTerm = router.query?.query ? decodeURIComponent(decodeURIComponent(router.query.query)) : "";
  const {
    type
  } = useResourceType();
  const [searchValue, setSearchValue] = useState(searchQueryTerm);
  const [activeSearchTab, setActiveSearchTab] = useState(getInitialActiveTab({
    type,
    location: router
  }));
  const isSellTabActive = useMemo(() => activeSearchTab === SELL_STATE, [activeSearchTab]);
  const isBuyTabActive = useMemo(() => activeSearchTab === BUY_STATE, [activeSearchTab]);
  const searchPlaceholderByTabs = useMemo(() => isSellTabActive ? "Search for ISBN number..." : "Search for ISBN number, or keyword...", [isSellTabActive]);
  const parsedIsbns = useMemo(() => parseMultipleGridIsbns({
    isbns: searchValue,
    withNotification: false
  }), [searchValue]);
  const searchPlaceholder = useMemo(() => searchPlaceholderByTabs, [searchPlaceholderByTabs, isSellTabActive]);
  const handleMultipleIsbnModal = useCallback(() => {
    modalAction({
      type: ModalTypes.MULTIPLE_ISBNS,
      title: "Add multiple ISBNs",
      modalStyles: {
        maxWidth: "630px"
      }
    });
  }, []);
  useEffect(() => {
    setIsMobileFlag(isMobileOnly);
    setIsTabletFlag(isTablet);
  }, []);
  useEffect(() => {
    if (router.asPath !== SEARCH) {
      setSearchValue("");
    } else {
      setSearchValue(searchQueryTerm);
    }
    setActiveSearchTab(getInitialActiveTab({
      type,
      location: router
    }));
  }, [router.asPath]);
  useEffect(() => {
    // set search focus by default and by history change
    if (!isMobile && searchRef.current) {
      searchRef.current.focus();
    }
  }, [router]);
  const handleClick = () => {
    if (isSellTabActive && parsedIsbns.length > 1) {
      router.push(`${BULK_COMPARISON}${generateQueryIsbnUrl({
        isbns: parsedIsbns
      })}`);
      return;
    }

    // check ref value for code scanners
    if (searchValue.length || searchRef.current.value.length) {
      window.dataLayer.push({
        event: "search",
        type: "clickMain"
      });
      const isText = searchValue.match(/[a-zA-Zа-яА-Я]/g);
      router.push(getSearchQueryString({
        searchValue: searchValue || searchRef.current.value,
        activeSearchTab: !isText && activeSearchTab
      }));
    }
  };
  const onCameraSearch = useCallback(async () => {
    if (navigator && !isScanning) {
      try {
        setIsScanning({
          dispatch,
          isScanning: true
        });
      } catch (err) {
        addErrorNotification({
          msg: err.message
        });
      }
    }
  }, [isScanning]);
  const handleChange = event => setSearchValue(event.target.value);
  const handleKeyDown = event => event.key === "Enter" && handleClick();
  const getSearchTabs = () => searchTabsConfig.map(({
    path,
    label,
    type,
    id
  }, key) => {
    const redirectPath = type === SELL_STATE && parsedIsbns.length > 1 ? `${BULK_COMPARISON}${generateQueryIsbnUrl({
      isbns: parsedIsbns
    })}` : searchValue.length ? getSearchQueryString({
      searchValue,
      activeSearchTab: type
    }) : path;
    const isTabActive = activeSearchTab === type;
    return <Link key={key} aria-label={`${label} tab`} passHref href={redirectPath} scroll={false}>
                    <S.Tab data-test-id={id} className={isTabActive && "active"}>
                        {label}
                    </S.Tab>
                </Link>;
  });
  return <S.StyledContainer>
            <S.SearchTabsPanel>{getSearchTabs()}</S.SearchTabsPanel>
            <S.Container>
                <S.SearchContainer>
                    <S.SearchIconContainer>
                        <S.SearchIcon />
                    </S.SearchIconContainer>
                    <S.SearchInput ref={searchRef} type="text" value={searchValue} placeholder={searchPlaceholder} onKeyPress={handleKeyDown} onChange={handleChange} />
                    <S.CameraSearch data-test-id="Scanner button" onClick={onCameraSearch} isMobile={isMobileFlag} show={isMobileFlag || isTabletFlag} />
                    <S.MultiLookup data-test-id="Multi lookup button" onClick={handleMultipleIsbnModal} isMobile={isMobileFlag} show={!isMobileFlag && !isTabletFlag && isSellTabActive} />
                    <S.SearchButton data-test-id={"Search button"} variant={ACCENT} onClick={handleClick} {...isMobileFlag ? {
          icon: zoomIcon
        } : {
          label: "SCOUT"
        }} />
                </S.SearchContainer>
                <S.SearchSubContent>
                    {isSellTabActive && <div>
                            <S.TippyStyled interactive={true} visible={isMultiLookupHintVisible} placement="bottom" maxWidth={546} onClickOutside={() => setIsMultiLookupHintVisible(false)} content={<S.TippyContent>
                                        To compare prices of multiple books at the same time, just
                                        enter a list of ISBNs separated by commas or spaces. For
                                        example, searching for &quot;9780393347258, 9780451205766,
                                        9780312361815&quot; will return a price comparison for all
                                        three books.
                                    </S.TippyContent>}>
                                <S.SearchHint onClick={() => setIsMultiLookupHintVisible(true)}>
                                    Compare multiple books at the same time!
                                </S.SearchHint>
                            </S.TippyStyled>
                        </div>}
                    {isBuyTabActive && <div>
                            <S.SearchHintWrapper>
                                <S.SearchHint notClickable>
                                    Try our{" "}
                                    <Link href={ADVANCED_SEARCH_PARAMS} passHref>
                                        Advanced Search
                                    </Link>{" "}
                                    to find a book by author, title, publisher, etc.
                                </S.SearchHint>
                            </S.SearchHintWrapper>
                        </div>}
                </S.SearchSubContent>
            </S.Container>
        </S.StyledContainer>;
};
export default memo(Search);