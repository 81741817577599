import React, { useEffect, useMemo, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Link from "next/link";
import VideoPlayer from "components/VideoPlayer";
import SearchMain from "components/SearchMain";
import Slider from "components/Slider";
import BubblesBanner from "components/BubblesBanner";
import { useAuthContext } from "contexts";
import { isPresentValue } from "common/utils";
import { addErrorNotification } from "common/notifications";
import { BUY, MAIN, REGISTER, LOGIN, CONTACT, ACCOUNT_PROFILE, SCHOLARSHIP, PRO, HIGH_VALUE } from "common/constants/pathRoutes";
import { SELL_STATE, BUY_STATE, RENT_STATE } from "common/constants/searchTypes";
import Anchors from "common/constants/anchors";
import { VIDEO_MAIN_PAGE } from "common/constants/youtubeVideoids";
import { useResourceType } from "common/hooks";
import ModalTypes from "common/constants/modalTypes";
import { useModalContext } from "contexts/Modal";
import { getOrderedCategories, getBlogPostCards, getCategoriesLinks, recommendationCardsConfig, opportunitiesCardsConfig, stepListConfig, renderEmptyContent, getShimmeredCategories, renderPostsContent, getShimmeredPosts, getBooksContent } from "./utils";
import { tabsContentConfig, recommendationDescriptionConfig } from "./utils/config";
import { fetchPostsData, fetchCategoriesData, fetchAllVendors } from "./actions";
import { reducer, setLazyInitState } from "./reducers";
import * as S from "./MainPage.styled";
const Main = ({
  prefetchedData
}) => {
  const router = useRouter();
  const {
    type
  } = useResourceType({
    type: SELL_STATE
  });
  const [state, dispatch] = useReducer(reducer, {
    prefetchedData,
    type,
    router
  }, setLazyInitState);
  const [{
    isAuth,
    user: {
      isProUser
    }
  }] = useAuthContext();
  const {
    modalAction
  } = useModalContext();
  const {
    books
  } = prefetchedData;
  const {
    isCategoriesLoading,
    isPostsLoading,
    isBooksLoading,
    categories,
    posts,
    basePath,
    vendors
  } = state;
  const orderedCategoryList = useMemo(() => getOrderedCategories(categories), [categories]);
  const handleContactModal = useCallback(() => {
    modalAction({
      type: ModalTypes.CONTACT,
      title: "Leave a Comment"
    });
  }, []);
  const handleLoginModal = useCallback(() => {
    modalAction({
      type: ModalTypes.LOG_IN,
      title: "Log in",
      childrenProps: {
        handleResetPasswordModal,
        handleSignUpModal
      }
    });
  }, []);
  const handleResetPasswordModal = useCallback(() => {
    modalAction({
      type: ModalTypes.RESET_PASSWORD,
      title: "Reset Password",
      childrenProps: {
        handleLoginModal
      }
    });
  }, []);
  const handleSignUpModal = useCallback(() => {
    modalAction({
      type: ModalTypes.SIGN_UP,
      title: "Sign up",
      childrenProps: {
        handleLoginModal,
        handleSignUpProModal
      }
    });
  }, []);
  const handleSignUpProModal = useCallback(() => {
    modalAction({
      type: ModalTypes.SIGN_UP_PRO,
      title: "Sign up for BookScouter Pro"
    });
  }, []);
  useEffect(() => {
    if (isAuth && (basePath === LOGIN || basePath === REGISTER)) {
      router.push(ACCOUNT_PROFILE);
    } else {
      if (basePath === LOGIN) {
        handleLoginModal();
        router.push(MAIN);
      }
      if (basePath === REGISTER) {
        handleSignUpModal();
        router.push(MAIN);
      }
    }
    if (basePath === CONTACT) {
      handleContactModal();
      router.push(MAIN);
    }

    // https://stackoverflow.com/questions/7131909/facebook-callback-appends-to-return-url
    if (basePath === "/#_=_") {
      router.replace(MAIN, undefined, {
        shallow: true
      });
    }
  }, [isAuth]);
  useEffect(() => {
    // extra case when errors push to url
    if (isPresentValue(router.query.error_description)) {
      addErrorNotification({
        msg: router.query.error_description
      });
      router.replace(MAIN, undefined, {
        shallow: true
      });
    }
  }, []);
  useEffect(() => {
    fetchPostsData({
      dispatch
    });
    fetchCategoriesData({
      dispatch
    });
  }, []);
  useEffect(() => {
    const vendorsType = [RENT_STATE, BUY_STATE].includes(type) ? BUY_STATE : SELL_STATE;
    fetchAllVendors(dispatch, vendorsType);
  }, [router.asPath]);
  const tabsContent = useMemo(() => tabsContentConfig[router.asPath] || tabsContentConfig[MAIN], [router.asPath]);
  const shouldDisplayVideo = useMemo(() => router.asPath !== BUY, [router.asPath]);
  const stepsContent = useMemo(() => isPresentValue(stepListConfig[router.asPath]) ? stepListConfig[router.asPath] : stepListConfig.default, [router.asPath]);
  const opportunitiesContent = useMemo(() => isPresentValue(opportunitiesCardsConfig[router.asPath]) ? opportunitiesCardsConfig[router.asPath] : opportunitiesCardsConfig.default, [router.asPath]);
  const recommendationDescriptionContent = useMemo(() => recommendationDescriptionConfig[router.asPath] || recommendationDescriptionConfig[MAIN], [router.asPath]);
  const recommendationContent = useMemo(() => isPresentValue(recommendationCardsConfig[router.asPath]) ? recommendationCardsConfig[router.asPath] : recommendationCardsConfig.default, [router.asPath]);
  const booksContent = useMemo(() => getBooksContent({
    books,
    isBooksLoading
  }), [isBooksLoading, books]);
  const categoriesContent = useMemo(() => isCategoriesLoading ? getShimmeredCategories() : orderedCategoryList.length ? getCategoriesLinks(orderedCategoryList) : renderEmptyContent("No categories loaded..."), [isCategoriesLoading, orderedCategoryList]);
  const postsContent = useMemo(() => isPostsLoading ? renderPostsContent(getShimmeredPosts()) : posts.length ? renderPostsContent(getBlogPostCards(posts)) : renderEmptyContent("No posts loaded..."), [isPostsLoading, posts]);
  const {
    action,
    pageTitle
  } = tabsContent;
  return <S.MainPageContainer data-test-id="MainPage-PageMainContainer">
            <S.SearchMainPageSection data-test-id="MainPage-MainPageSection" filled>
                <S.SearchSectionWrapper>
                    <S.HeaderSearchContent type={type}>
                        <S.HeaderSearchTitle data-test-id="MainHeader-HeaderSearchTitle">
                            {pageTitle}{" "}
                            <S.NoBreak>
                                at the <S.StrongSearchTitle>best&nbsp;price</S.StrongSearchTitle>
                            </S.NoBreak>
                        </S.HeaderSearchTitle>
                        <SearchMain data-test-id="MainHeader-SearchMain" />
                    </S.HeaderSearchContent>
                    <S.BubblesContainer>
                        <BubblesBanner vendors={vendors} pageType={type} />
                    </S.BubblesContainer>
                </S.SearchSectionWrapper>
            </S.SearchMainPageSection>
            <S.StepContentSection data-test-id="MainPage-MainPageSection" filled>
                <S.StepContent data-test-id="MainPage-StepContent">{stepsContent}</S.StepContent>
            </S.StepContentSection>
            <S.AdvantagesSection data-test-id="MainPage-MainPageSection">
                <S.OpportunitiesBooksContent data-test-id="MainPage-OpportunitiesBooksContent">
                    <S.SubTitleCentered data-test-id="MainPage-SubTitle">
                        Why {action} books with BookScouter?
                    </S.SubTitleCentered>
                    <S.OpportunitiesItems>{opportunitiesContent}</S.OpportunitiesItems>
                </S.OpportunitiesBooksContent>
            </S.AdvantagesSection>
            <S.MainPageSection data-test-id="MainPage-MainPageSection" filled>
                <S.SuggestedBooksWrapper>
                    <div data-test-id="MainPage-RecentlyBooksContent">
                        <S.SubTitle data-test-id="MainPage-Recently-SubTitle">
                            Recently Searched High Resale Value Books
                        </S.SubTitle>
                        <div>{booksContent}</div>
                        {/* eslint-disable-next-line max-len */}
                        <S.RecentDescription data-test-id="MainPage-HighResaleBooksDescription">
                            Analyze book prices and identify the best categories and titles with
                            BookScouter Pro
                        </S.RecentDescription>
                        <Link passHref href={isProUser ? HIGH_VALUE : `${PRO}#${Anchors.HIGH_VALUE}`} prefetch={false}>
                            {/* eslint-disable-next-line max-len */}
                            <S.RecentLink data-test-id="MainPage-HighResaleBooksLink">
                                Learn More
                            </S.RecentLink>
                        </Link>
                    </div>
                </S.SuggestedBooksWrapper>
            </S.MainPageSection>
            <S.MainPageSection data-test-id="MainPage-MainPageSection">
                <S.MobileAppContent data-test-id="MainPage-MobileAppContent">
                    <S.MobileWrapper data-test-id="MainPage-MobileWrapper">
                        <S.MobileTextContent data-test-id="MainPage-MobileTextContent">
                            <S.SubTitle data-test-id="MainPage-SubTitle">
                                Get Our Mobile App
                            </S.SubTitle>
                            <S.MobileDescription data-test-id="MainPage-MobileDescription">
                                Quickly scan tons of books, view vendors, ratings, and buyback
                                prices at any time from the convenience of your phone for free!
                            </S.MobileDescription>
                            <S.MobileLinkAccent href={"http://itunes.apple.com/us/app/bookscouter-mobile/" + "id366508853"} target="_blank" data-test-id="MainPage-MobileLinkAccent" rel={"nofollow noopener noreferrer"}>
                                Download for iOS
                            </S.MobileLinkAccent>
                            <S.MobileLinkPrimary href={"https://play.google.com/store/apps/details?" + "id=com.BookScouter"} target="_blank" data-test-id="MainPage-MobileLinkPrimary" rel={"nofollow noopener noreferrer"}>
                                Download for Android
                            </S.MobileLinkPrimary>
                        </S.MobileTextContent>
                    </S.MobileWrapper>
                </S.MobileAppContent>
            </S.MainPageSection>
            <S.MainPageSection data-test-id="MainPage-MainPageSection" filled>
                <S.ScholarshipContent data-test-id="MainPage-ScholarshipContent">
                    <S.ScholarshipWrapper data-test-id="MainPage-ScholarshipWrapper">
                        <S.ScholarshipTextContent data-test-id={"MainPage-ScholarshipTextContent"}>
                            <S.SubTitle data-test-id="MainPage-SubTitle">
                                $500 Quarterly Book Scholarship
                            </S.SubTitle>
                            {/* eslint-disable-next-line max-len */}
                            <S.ScholarshipDescription data-test-id="MainPage-ScholarshipDescription">
                                BookScouter is sponsoring a textbook scholarship! Each quarter we
                                will award a student with a $500 scholarship to be used towards
                                purchasing their textbooks.
                            </S.ScholarshipDescription>
                            <Link passHref href={SCHOLARSHIP}>
                                {/* eslint-disable-next-line max-len */}
                                <S.ScholarshipLinkAccent data-test-id="MainPage-ScholarshipLinkAccent">
                                    Learn More
                                </S.ScholarshipLinkAccent>
                            </Link>
                        </S.ScholarshipTextContent>
                    </S.ScholarshipWrapper>
                </S.ScholarshipContent>
            </S.MainPageSection>
            <S.MainPageSection data-test-id="MainPage-MainPageSection">
                <S.BlogPostContent data-test-id="MainPage-BlogPostContent">
                    <S.SubTitle data-test-id="MainPage-SubTitle">Recent Blog Posts</S.SubTitle>
                    {postsContent}
                    <S.BlogPostCategories data-test-id="MainPage-BlogPostCategories">
                        <S.BlogPostCategoriesTitle data-test-id={"MainPage-BlogPostCategoriesTitle"}>
                            Explore More Topics
                        </S.BlogPostCategoriesTitle>
                        {categoriesContent}
                    </S.BlogPostCategories>
                </S.BlogPostContent>
            </S.MainPageSection>
            {shouldDisplayVideo && <S.MainPageSection data-test-id="MainPage-MainPageSection" filled withMargin>
                    <S.VideoContent data-test-id="MainPage-VideoContent">
                        <S.VideoTextContent data-test-id="MainPage-VideoTextContent">
                            <S.VideoTextPreview data-test-id="MainPage-VideoTextPreview">
                                While you’re here…
                            </S.VideoTextPreview>
                            <S.SubTitle data-test-id="MainPage-VideoContentTitle">
                                Did you know BookScouter <br /> also helps you buy textbooks?
                            </S.SubTitle>
                            <S.VideoText data-test-id="MainPage-VideoText">
                                Search our BUY section to compare textbook prices from more than 25
                                bookstores. Once you’ve found the lowest price, you’ll be able to
                                purchase the textbooks for the most affordable price.
                            </S.VideoText>
                        </S.VideoTextContent>
                        <S.PlayerWrapper data-test-id="MainPage-PlayerWrapper">
                            <VideoPlayer embedId={VIDEO_MAIN_PAGE} name="How to Use BookScouter?" data-test-id="MainPage-VideoPlayer" />
                        </S.PlayerWrapper>
                    </S.VideoContent>
                </S.MainPageSection>}
            <S.MainPageSection data-test-id="MainPage-RecommendationContainer">
                <S.RecommendationContent data-test-id="MainPage-RecommendationContent">
                    <S.SubTitle data-test-id="MainPage-SubTitle">
                        Why should you {action} textbooks using BookScouter?
                    </S.SubTitle>
                    <S.RecommendationDescription data-test-id={"MainPage-RecommendationDescription"}>
                        {recommendationDescriptionContent}
                    </S.RecommendationDescription>
                    <S.SliderWrapper>
                        <Slider>{recommendationContent}</Slider>
                    </S.SliderWrapper>
                    <S.RecommendationCards data-test-id="MainPage-RecommendationCards">
                        {recommendationContent}
                    </S.RecommendationCards>
                </S.RecommendationContent>
            </S.MainPageSection>
        </S.MainPageContainer>;
};
Main.propTypes = {
  prefetchedData: PropTypes.object
};
Main.defaultProps = {
  prefetchedData: null
};
export default Main;